@import '~loaders.css';

.ball-scale-multiple {
  position: absolute !important;
  top: 50%;
  right: 50%;

  & > div {
    background-color: #47b7f6 !important;
  }
}

.showWithDelay {
  animation: 10s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
  position: absolute;
  bottom: 25px;
  right: 30px;
  text-transform: uppercase;
  color: #ff5722;
}
